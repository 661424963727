*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

img.picture{
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
