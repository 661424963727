@import url("https://fonts.googleapis.com/css2?family=Itim&family=Poppins:wght@300;400;500;600&display");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

:root {
  --color-bg: #edeae8;
  --color-bg-variant: #dbd5d2;
  --color-primary: #F8F2F1;
  --color-primary-variant: #c83a28;
  --color-secondary: rgba(255, 255, 255, 0.3);
  --color-white: #F8F2F1;
  --color-black: #070303;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-dark: rgba(0, 0, 0, 0.6);
  --color-header: #008f8c;
  --color-cursor: #333;
  --color-btn: rgba(255, 255, 255, 0.1);
  --color-btn-hover: rgb(148, 0, 0);

  --transition: all 0.4s ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
  background-color: var(--color-black);
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  /* background: var(--color-bg);
  background-size: 128rem; */
  color: var(--color-black);
  line-height: 1.7;
}

/* General Styles */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  scale: 1.1;
}

.btn {
  width: max-content;
  display: block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-dark);
  transition: var(--transition);
  background-color: var(--color-btn);
}

.btn:hover {
  background: var(--color-btn-hover);
  color: var(--color-white);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* Media Queries (Medium) */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* Media Queries (Small) */

@media screen and (max-width: 768px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}
