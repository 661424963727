.socials {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.react-icons {
  font-size: 2rem;
  margin: 0 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
