.header {
  width: 100%;
  height: 6rem;
  padding: 0 2rem;
}

.menu {
  display: none;
}

.inner-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.logo {
  cursor: pointer;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content a {
  font-size: 1.5rem;
  margin-left: 1rem;
}

@media screen and (max-width: 768px) {
  .header {
    height: 5rem;
  }

  .menu {
    display: block;
    cursor: pointer;
    font-size: 2rem;
    color: var(--color-primary);
  }

  .header-content {
    display: block;
    position: absolute;
    top: -50%;
    left: 0;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.9);
    transition: 1s ease;
  }

  .open {
    top: 5rem;
  }

  .header-content a {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    font-size: 1.5rem;
    margin: 0;
  }
}
