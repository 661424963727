.about {
  color: var(--color-primary);
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 6rem auto;
}

.about-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-content h1 {
  font-size: 3rem;
}

.about-content h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}


@media screen and (max-width: 768px) {
  .about {
    height: 100%;
  }

  .about-content h1{
    font-size: 2rem;
  }

}
