.experience {
    color: var(--color-primary);
    width: 100%;
    flex-direction: row;
    text-align: center;
}

.experience h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
}

.experience-carosel {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
    /* align-items: center; */
    margin: 2rem auto;
}

.experience-item {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem auto;
    background-color: var(--color-secondary);
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


.experience-item img {
    display: block;
    float: right;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
}

.experience p {
    padding-top: 1rem;
}

@media screen and (max-width: 768px) {
    .experience {
        flex-direction: column;
    }
    .experience h1 {
        font-size: 2.5rem;
    }
    .experience-item img {
        width: 6rem;
        height: 6rem;
    }
    .experience-item {
        padding: 1rem;
    }
    .experience-carosel {
        flex-direction: column;
    }
}